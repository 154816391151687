import logo from '../../../assets/HORIZON_oneline.png';

function Crisis24Logo() {
  return (
    <img
      src={logo}
      alt="Crisis24 Horizon Logo"
    />
  )
}

export default Crisis24Logo;