import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Layout} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

import {useEffect} from 'react';
import {getOrgClientLogo} from '../../../redux/modules/session/actions';
import {ORGANIZATION_CONFIGURATION_TYPES} from '../../../containers/Organizations/OrganizationProfilePage/tabs/OrganizationClientConfiguration/ClientConfiguration.constants';
import HeaderLogo from './HeaderLogo';

import './HeaderBar.css';

/**
 * Base app header bar.
 * Add additional contents as children
 */
export default function HeaderBar (props){

  const {children, className, onToggle, isMobile} = props;

  const dispatch = useDispatch();

  const orgId = useSelector(state => state.session.currentPermissions.orgId);

  useEffect(() => {
    if(orgId) {
      dispatch(getOrgClientLogo(orgId, ORGANIZATION_CONFIGURATION_TYPES.CUSTOM_LOGO));
    }
  }, [orgId, dispatch]);

  const clientLogoUrl = useSelector(state => state.session.orgClientLogoUrl);

  return (
    <Layout.Header className={classNames('HeaderBar', className)}>

      <div className={'logo-wrapper'} >

        {isMobile && (
          <div
            className="menu-btn header-btn"
            onClick={onToggle}
          >
            <FontAwesomeIcon icon={faBars} size="lg"/>
          </div>
        )}

        <HeaderLogo clientLogoUrl={clientLogoUrl} />

        {children}

      </div>

    </Layout.Header>
  );

}

HeaderBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isMobile: PropTypes.bool,
  onToggle: PropTypes.func
};
