import {Grid} from 'antd';

const {useBreakpoint} = Grid;

/**
 * Encapsulates the ant design breakpoints into a set of boolean properties that return true
 * for only the current breakpoint
 * */
function useAntDesignBreakPoints() {

  const screens = useBreakpoint();

  const {xs, sm, md, lg, xl, xxl} = screens;

  /**
   * True if the current breakpoint is 'xs', i.e. between 320px and 575px
   * */
  const isExtraSmall = xs && !(sm || md || lg || xl || xxl);

  /**
   * True if the current breakpoint is 'sm', i.e. between 576px and 767px
   * */
  const isSmall = sm && !(xs || md || lg || xl || xxl);

  /**
   * True if the current breakpoint is 'md', i.e. between 768px and 991px
   * */
  const isMedium = md && !(lg || xl || xxl)

  /**
   * True if the current breakpoint is 'lg', i.e. between 992px and 1199px
   * */
  const isLarge = lg && !(xl || xxl);

  /**
   * True if the current breakpoint is 'xl', i.e. between 1200px and 1599px
   * */
  const isExtraLarge = sm && md && lg && xl;

  /**
   * True if the current breakpoint is 'xxl', i.e. between 1600px and 2100px
   * */
  const isExtraExtraLarge = sm && md && lg && xl && xxl;

  return {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isExtraExtraLarge
  }
}

export default useAntDesignBreakPoints;