import PropTypes from 'prop-types';

const ClientOrganizationLogo = (props) => {

  const {clientLogoUrl} = props;

  return (
    <img src={clientLogoUrl} alt="client top logo" className="client-logo-top-bar" />
  )
}

ClientOrganizationLogo.propTypes = {
  clientLogoUrl: PropTypes.string
}

export default ClientOrganizationLogo;
