import _ from 'lodash';
import moment from 'moment';

import mapboxUtils, {MAP_STYLES} from '../../../components/Common/Mapbox/utils/mapbox-utils';
import {getRatingKey} from '../../../utils/rating-filters';
import {getCategoryName} from '../categories/utils';
import {LAYERS} from '../../../components/Common/Mapbox/layers/layerIds';
import {
  LOCATION_TIERS,
  MAP_RATINGS,
  MAP_RATINGS_CATEGORY,
  MAP_RATINGS_LEVELS,
  MAP_RATINGS_ORGS,
  RATINGS_DEFAULT_RANGE,
  WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE
} from '../../../constants/rating-filters';
import {VIEW_CITY_RATINGS, VIEW_PROVINCE_RATINGS, VIEW_SUB_CATEGORY_RATINGS} from '../../../constants/permissions';
import {
  ALERT_CATEGORIES,
  ALERT_INTEL_TYPE,
  ALERT_REQUIRE_EXPOSURE,
  ALERT_SEVERITIES,
  INCIDENT_IMPACT_LEVELS,
  INCIDENT_INTEL_TYPE,
  SITE_REQUIRE_EXPOSURE,
  WORLD_MAP_FILTER_STORAGE_KEY_BASE
} from '../../../constants/alert-filters';
import {MAP_LOCATIONS_FILTER} from '../../../constants/map';
import {WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE} from '../../../constants/person-filters';
import {CATEGORY_IDS} from '../../../constants/categories';
import * as types from './types';
import {
  THREAT_ZONE_LEVELS,
  THREAT_ZONE_ORGS,
  THREAT_ZONES,
  WORLD_MAP_FILTER_TZ_STORAGE_KEY_BASE
} from '../../../constants/threat-zones-filters';
import * as turf from '@turf/turf';
import {
  ADDRESSES_LOCATIONS_RADIO,
  AIRLINE_ID,
  AIRPORT_ID,
  ALL,
  FLIGHT_NUMBER_LIKE,
  INCLUDE_SUB_ORGS,
  ML_ALL,
  PERSON_GROUP,
  PERSON_MOBILE_LOCATIONS_RADIO,
  PERSON_ORGS,
  SEPARATE_MOBILE_LOCATIONS
} from '../../../components/Person/PersonOptionsSelection/form-constants';
import {
  getCurrentOrgs,
  getPersonAdvanceSearchParams,
  parsePersonPolygonFeatureResult,
  PERSON_LOCATION_TYPES_EXCLUDED_VALUES
} from './person/utils';
import {parseThreatZonePolygonFeatureResult} from './threat-zone/utils';
import {
  formRatingObjectFromFeatureRatings,
  getRatingCurrentSelectedOrgPermissions,
  isProvinceRatingFeature,
  isRatingFeature,
  parseRatingsPolygonFeatureResult
} from './rating/utils';
import {
  createAlertsFilterFromState,
  getSeverityMappinsValues,
  isAlertFeature,
  isAllAlertLayersTurnedOn,
  parseAlertsPolygonFeatureResult
} from './alert/utils';
import {horizonLocalStorage} from '../../../utils/local-storage';
import {parseSitePolygonFeatureResult} from './site/utils';
import {DEFAULT_PARENT_ID} from '../../../components/Organizations/constants';
import {reduceTree} from '../organization/utils';
import {constants} from '../persons/utils';
import {COUNT_AS_FILTER, SITE_FILTER, SITE_TYPES_FILTER, WORLD_MAP_FILTER_SITES_STORAGE_KEY_BASE} from '../../../constants/map-sites';
import {SITE_COUNT_AS_VALUES} from '../../../components/WorldMap/MapMenuDrawer/MapFilterDrawer/SiteTab/constants';

export const MAX_ZOOM = 16;

const WORLD_MAP_STORAGE_KEY = 'world-map-state';

export const STATE_PROP = {
  DETAIL_FEATURE: 'detailFeature',
  PROVINCE_FEATURE: 'provinceFeature'
};

export const DEFAULT_FILTER_VALUES = {
  layerVisibility: {
    alerts: true,
    countryRatings: false,
    countryThreatZone: false,
    provinceThreatZone: false,
    cityThreatZone: false,
    person: false
  },
  languageUnsupportedWarningShown: false,
  locationsFilter: undefined,
  alertsFilter: {
    severities: getSeverityMappinsValues(),
    [INCIDENT_IMPACT_LEVELS]: [],
    categories: []
  },
  ratingsFilter: {
    [MAP_RATINGS]: [],
    [MAP_RATINGS_CATEGORY]: CATEGORY_IDS.OVERALL,
    [MAP_RATINGS_LEVELS]: RATINGS_DEFAULT_RANGE
  },
  threatZoneFilter: {
    [THREAT_ZONES]: [],
    [THREAT_ZONE_LEVELS]: RATINGS_DEFAULT_RANGE
  },
  personFilter: {
    [PERSON_GROUP]: [],
    [PERSON_MOBILE_LOCATIONS_RADIO]: ML_ALL,
    [ADDRESSES_LOCATIONS_RADIO]: ALL,
    [PERSON_ORGS]: [],
    includeSubOrganizations: undefined
  },
  siteFilter: {
    [SITE_FILTER]: false,
    [COUNT_AS_FILTER]: SITE_COUNT_AS_VALUES.SITES,
    [SITE_TYPES_FILTER]: undefined
  },
  mapType: MAP_STYLES.STREET.id
};

export const MAX_NONEXTENDED_BOUNDS_ZOOM = 4;
export const MAX_EXTENDED_BOUNDS = turf.envelope(turf.multiPoint([[-180, -90], [180, 90]]));

/**
 * Return the mapType stored in LocalStorage.
 * If no style is stored, or if the style stored is invalid, return 'street'.
 * @param worldMapLocalStorage
 * @return {string}
 */
export function getMapTypeFromLocalStore(worldMapLocalStorage) {
  let mapType = _.get(worldMapLocalStorage, 'mapType', MAP_STYLES.STREET.id);

  if (!mapboxUtils.getMapStyles().includes(mapType)) {
    //maptype is invalid, default to street
    mapType = MAP_STYLES.STREET.id;
  }
  return mapType;
}

/**
 * Returns the local storage object for 'world-map-state' key
 *
 * @returns {*}
 */
export function getWorldMapLocalStorage() {
  let worldMapLocalStorage = {};
  try {
    worldMapLocalStorage = JSON.parse(horizonLocalStorage.getItem(WORLD_MAP_STORAGE_KEY));
  }
  catch (e) {
    // do nothing, variable will get set below
  }
  return {
    layerVisibility: {
      alerts: _.get(worldMapLocalStorage, 'layerVisibility.alerts', DEFAULT_FILTER_VALUES.layerVisibility.alerts),
      countryRatings: _.get(worldMapLocalStorage, 'layerVisibility.countryRatings',
        DEFAULT_FILTER_VALUES.layerVisibility.countryRatings),
      countryThreatZone: _.get(worldMapLocalStorage, 'layerVisibility.countryThreatZone',
        DEFAULT_FILTER_VALUES.layerVisibility.countryThreatZone),
      provinceThreatZone: _.get(worldMapLocalStorage, 'layerVisibility.provinceThreatZone',
        DEFAULT_FILTER_VALUES.layerVisibility.provinceThreatZone),
      cityThreatZone: _.get(worldMapLocalStorage, 'layerVisibility.cityThreatZone',
        DEFAULT_FILTER_VALUES.layerVisibility.cityThreatZone),
      person: _.get(worldMapLocalStorage, 'layerVisibility.person', DEFAULT_FILTER_VALUES.layerVisibility.person)
    },
    languageUnsupportedWarningShown: _.get(worldMapLocalStorage, 'languageUnsupportedWarningShown',
      DEFAULT_FILTER_VALUES.languageUnsupportedWarningShown),
    locationsFilter: _.get(worldMapLocalStorage, [MAP_LOCATIONS_FILTER], DEFAULT_FILTER_VALUES.locationsFilter),
    alertsFilter: {
      severities: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_STORAGE_KEY_BASE, ALERT_SEVERITIES],
        DEFAULT_FILTER_VALUES.alertsFilter.severities),
      [INCIDENT_IMPACT_LEVELS]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_STORAGE_KEY_BASE, INCIDENT_IMPACT_LEVELS],
        DEFAULT_FILTER_VALUES.alertsFilter?.[INCIDENT_IMPACT_LEVELS]),
      categories: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_STORAGE_KEY_BASE, ALERT_CATEGORIES],
        DEFAULT_FILTER_VALUES.alertsFilter.categories),
      [ALERT_REQUIRE_EXPOSURE]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_STORAGE_KEY_BASE, ALERT_REQUIRE_EXPOSURE],
        false),
      [SITE_REQUIRE_EXPOSURE]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_STORAGE_KEY_BASE, SITE_REQUIRE_EXPOSURE],
        false)
    },
    ratingsFilter: {
      [MAP_RATINGS]: _.get(worldMapLocalStorage, [WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS],
        DEFAULT_FILTER_VALUES.ratingsFilter[MAP_RATINGS]),
      [MAP_RATINGS_CATEGORY]: _.get(worldMapLocalStorage,
        [WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS_CATEGORY],
        DEFAULT_FILTER_VALUES.ratingsFilter[MAP_RATINGS_CATEGORY]),
      [MAP_RATINGS_LEVELS]:
        _.get(worldMapLocalStorage, [WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS_LEVELS],
          DEFAULT_FILTER_VALUES.ratingsFilter[MAP_RATINGS_LEVELS]),
      [MAP_RATINGS_ORGS]:
        _.get(worldMapLocalStorage, [WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS_ORGS])
    },
    threatZoneFilter: {
      [THREAT_ZONES]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_TZ_STORAGE_KEY_BASE, THREAT_ZONES], []),
      [THREAT_ZONE_LEVELS]:
        _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_TZ_STORAGE_KEY_BASE, THREAT_ZONE_LEVELS],
          DEFAULT_FILTER_VALUES.threatZoneFilter[THREAT_ZONE_LEVELS]),
      [THREAT_ZONE_ORGS]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_TZ_STORAGE_KEY_BASE, THREAT_ZONE_ORGS])
    },
    personFilter: {
      [PERSON_GROUP]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, PERSON_GROUP],
        DEFAULT_FILTER_VALUES.personFilter[PERSON_GROUP]),
      [PERSON_MOBILE_LOCATIONS_RADIO]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, PERSON_MOBILE_LOCATIONS_RADIO],
        DEFAULT_FILTER_VALUES.personFilter[PERSON_MOBILE_LOCATIONS_RADIO]),
      [ADDRESSES_LOCATIONS_RADIO]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, ADDRESSES_LOCATIONS_RADIO],
        DEFAULT_FILTER_VALUES.personFilter[ADDRESSES_LOCATIONS_RADIO]),
      [PERSON_ORGS]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, PERSON_ORGS],
        DEFAULT_FILTER_VALUES.personFilter[PERSON_ORGS]),
      [INCLUDE_SUB_ORGS]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, INCLUDE_SUB_ORGS],
        DEFAULT_FILTER_VALUES.personFilter[INCLUDE_SUB_ORGS]),
      [SEPARATE_MOBILE_LOCATIONS]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, SEPARATE_MOBILE_LOCATIONS],
        DEFAULT_FILTER_VALUES.personFilter[SEPARATE_MOBILE_LOCATIONS])
    },
    [WORLD_MAP_FILTER_SITES_STORAGE_KEY_BASE]: {
      [SITE_FILTER]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_SITES_STORAGE_KEY_BASE, SITE_FILTER],
        DEFAULT_FILTER_VALUES.siteFilter[SITE_FILTER]),
      [COUNT_AS_FILTER]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_SITES_STORAGE_KEY_BASE, COUNT_AS_FILTER],
        DEFAULT_FILTER_VALUES.siteFilter[COUNT_AS_FILTER]),
      [SITE_TYPES_FILTER]: _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_SITES_STORAGE_KEY_BASE, SITE_TYPES_FILTER],
        DEFAULT_FILTER_VALUES.siteFilter[SITE_TYPES_FILTER])
    },
    mapType: getMapTypeFromLocalStore(worldMapLocalStorage)
  };
}

/**
 * Compares the current local storage object for 'world-map-state' key to the default state.
 *
 * @param storageObject The current state of local storage to compare to the default state.
 * @returns boolean
 */
export const isDefaultLayerPermissions = (storageObject) => _.isEqual(DEFAULT_FILTER_VALUES, storageObject);

export function isTimeFilterOn(worldMapState) {
  return moment(worldMapState?.beginDateTime).diff(getDefaultStartTime(), 'minutes') > 5 ||
    moment(worldMapState?.endDateTime).diff(getDefaultEndTime(), 'minutes') > 5;
}

export function isPersonAdvanceSearchActive(worldMapState) {
  const personFilter = worldMapState?.personFilter;
  const isActive =
    personFilter?.[AIRLINE_ID]?.value ||
    personFilter?.[AIRPORT_ID]?.value ||
    personFilter?.[FLIGHT_NUMBER_LIKE] ||
    personFilter?.[constants.ADDRESS_ACCURACY]?.length > 0 ||
    personFilter?.[constants.ADDRESS_LABELS]?.length > 0;
  return isActive;
}

export const hasFilterAppliedToLayers = (mapFilters, getState) => {
  const permissionMap = _.get(getState(), ['session', 'currentPermissions', 'permissions'], {});

  const worldMapState = _.get(getState(), 'worldMap');
  const hasLocationsFilter = !_.isEmpty(mapFilters?.locationsFilter);

  const alertFilter = mapFilters?.alertsFilter;
  // if alerts is active
  if (mapFilters.layerVisibility?.alerts && !_.isEmpty(alertFilter?.severities)) {
    // if time range is different than default
    if (
      hasLocationsFilter ||
      isTimeFilterOn(worldMapState) ||
      worldMapState?.includeExpiredIntelligence ||
      !_.isEmpty(alertFilter?.categories) ||
      !isAllAlertLayersTurnedOn(permissionMap, alertFilter)
    ) {
      return true;
    }
  }

  const ratingFilter = mapFilters?.ratingsFilter;
  if (!_.isEmpty(ratingFilter?.mapRatings)) {
    // rating layer is on (country/province/city and a filter is applied)
    if (
      hasLocationsFilter ||
      ratingFilter?.[MAP_RATINGS_CATEGORY] !== CATEGORY_IDS.OVERALL ||
      !_.isEqual(ratingFilter?.[MAP_RATINGS_LEVELS]?.sort(), RATINGS_DEFAULT_RANGE.sort())
    ) {
      return true;
    }
  }

  if (mapFilters.layerVisibility?.person) {
    // if time range is different than default
    if (hasLocationsFilter || isTimeFilterOn(worldMapState) || isPersonAdvanceSearchActive(worldMapState)) {
      return true;
    }
  }

  return false;
};

/**
 * Sets the local storage object for 'world-map-state' key
 *
 * @returns {*}
 */
export function setWorldMapLocalStorage(storageObject) {
  horizonLocalStorage.setItem(WORLD_MAP_STORAGE_KEY, JSON.stringify(storageObject));
}

/**
 * Returns true if lat / long are inside the rectangle defined by
 * southwest and northeast. southwest in inclusive, northeast is not.
 *
 * @param long - longitude number
 * @param lat - latitude number
 * @param southwest - lng,lat array
 * @param northeast - lng,lat array
 * @returns {boolean}
 */
export function isPointInRectangle(long, lat, southwest, northeast) {
  return southwest[0] <= long
    && long < northeast[0]
    && southwest[1] <= lat
    && lat < northeast[1];
}

/**
 * Expands features to break out their cluster children when necessary and
 * removes duplication of incident feature items if exists
 *
 * @param features
 * @param getState to get redux state
 * @returns {*}
 */
export function transformFeatures(features, getState) {

  return _.reduce(features, (reducer, feature) => {

    const isClustered = _.get(feature, 'properties.cluster');
    const isAlert = isAlertFeature(feature);
    const isRating = isRatingFeature(feature);

    // Pull out alert clustered children and add individually
    if (isAlert && isClustered) {
      const expandedChildren = _.chain(feature.children)
        .map(child => ({
          ...feature,
          ...child,
          bounds: feature.bounds // keep bounds of entire cluster
        }))
        .uniqBy('properties.id')
        .sort((a, b) => b.properties?.severityKey?.localeCompare(a.properties?.severityKey))
        .value();

      return _.concat(reducer, expandedChildren);
    }

    // Map the stringified categories to array
    if (isAlert && !isClustered) {
      try {
        feature.properties.categories = JSON.parse(feature.properties.categories);
      }
      catch {
        // do nothing
      }
      return _.concat(reducer, feature);
    }

    if (isRating) {
      let clonedFeature = _.cloneDeep(feature);

      const currentCategoryId = _.get(getState(),
        ['worldMap', WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS_CATEGORY]);
      const ratingsDict = _.get(getState(), 'categories.dict', {});
      const ratingKey = getRatingKey(currentCategoryId);
      clonedFeature.currentCategoryRating = _.get(clonedFeature, ['properties', ratingKey]);
      clonedFeature.currentCategoryName = getCategoryName(ratingsDict, currentCategoryId);

      clonedFeature.currentCategoryId = currentCategoryId;
      clonedFeature.ratingsDict = ratingsDict;

      clonedFeature.detailsRatings = formRatingObjectFromFeatureRatings(feature, ratingsDict);

      if (clonedFeature.layer.id === LAYERS.RATINGS_CITY_LAYER_BOUNDARY_ID ||
        clonedFeature.layer.id === LAYERS.RATINGS_CITY_LAYER_POINT_ID) {
        const currentLanguage = _.get(getState(), 'localization.language');
        clonedFeature.properties = {
          ...clonedFeature.properties,
          locationId: clonedFeature.properties.id,
          locationName: clonedFeature.properties[`locationName_${currentLanguage}`],
          locationTier: clonedFeature.properties[`locationTierName_${currentLanguage}`],
          parentLocationName: clonedFeature.properties[`parentLocationName_${currentLanguage}`] ||
            clonedFeature.properties['parentLocationName_en']
        };
      }

      return _.concat(reducer, clonedFeature);
    }

    // Return everything else normally
    return _.concat(reducer, feature);

  }, []);
}

export function filterOrgsPerAvailablePersonas(state, currentLocaleStorageOrgs) {
  const fullPermissions = state?.session?.fullPermissions;
  const hasRoot = fullPermissions?.[DEFAULT_PARENT_ID];
  if (hasRoot) {
    return currentLocaleStorageOrgs;
  }

  const organizationHierarchy = state?.session?.organizationHierarchy;
  const currentAvailablePersonas = reduceTree(organizationHierarchy)?.map(org => org.id);
  return currentLocaleStorageOrgs?.filter(orgId => {
    return currentAvailablePersonas?.includes(orgId);
  });
}

export function buildPolygonSearchRequests(polygonFeature, layers, apis, store) {
  const worldMapState = _.get(store, ['worldMap']);
  const geoJson = JSON.stringify(polygonFeature.geometry);
  const locale = _.get(store, ['localization', 'locale']);
  const locationIds = _.get(store, ['worldMap', 'locationsFilter']);

  const allRequests = [];

  if (layers.alerts) {
    const filter = createAlertsFilterFromState(store);
    if (!_.isEmpty(filter?.alertSeverities) || !_.isEmpty(filter?.incidentImpactLevels)) {
      const intelTypes = [
        ...(filter?.alertSeverities?.length > 0 ? [ALERT_INTEL_TYPE] : []),
        ...(filter?.incidentImpactLevels?.length > 0 ? [INCIDENT_INTEL_TYPE] : [])
      ];
      allRequests.push(
        apis.exposures.getAllAlerts({...filter, intelTypes, polygons: [polygonFeature]}, locale)
          .then(response => parseAlertsPolygonFeatureResult(response))
      );
    }
  }

  if (layers.countryRatings || layers.cityRatings) {
    const permissionIds = getRatingCurrentSelectedOrgPermissions(store);
    const hasProvincePermission = _.includes(permissionIds, VIEW_PROVINCE_RATINGS);
    const hasCityPermission = _.includes(permissionIds, VIEW_CITY_RATINGS);

    const ratingOrgId = _.get(store, ['worldMap', 'ratingsFilter', MAP_RATINGS_ORGS]);

    const includeSubCategories = permissionIds.includes(VIEW_SUB_CATEGORY_RATINGS);
    let locationTiers = [];

    if (layers.countryRatings) {
      locationTiers.push(LOCATION_TIERS.COUNTRY);
      if (hasProvincePermission) {
        locationTiers.push(LOCATION_TIERS.PROVINCE);
      }
    }
    if (layers.cityRatings && hasCityPermission) {
      locationTiers.push(LOCATION_TIERS.CITY);
    }
    const formattedFilter = {
      locationNameLike: '',
      locationTiers,
      includeSubCategories,
      geoJson,
      locationFilter: {
        withinLocationIds: locationIds?.length > 0 ? locationIds : undefined
      }
    };

    allRequests.push(
      apis.ratings.getLocationRatings(formattedFilter, ratingOrgId, locale, 0, 500)
        .then(response => parseRatingsPolygonFeatureResult(response, store, locale))
    );
  }

  if (layers.person || layers.crisisSignal) {
    const dateStart = _.get(store, ['worldMap', 'beginDateTime']);
    const dateEnd = _.get(store, ['worldMap', 'endDateTime']);
    const personLocationTypesFilter = _.get(store, ['worldMap', WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, PERSON_GROUP], []);
    const personLocationScoreFilter = _.get(store, ['worldMap', WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, PERSON_MOBILE_LOCATIONS_RADIO]);

    const personExcludedLocationTypesValues = Object.keys(PERSON_LOCATION_TYPES_EXCLUDED_VALUES);
    const personLocationTypes = personLocationTypesFilter.filter(item => {
      return !personExcludedLocationTypesValues.includes(item);
    });

    const mobileLocationScope = personLocationScoreFilter === ML_ALL ? 'ALL' : 'LAST';
    const personOrgIds = getCurrentOrgs(store);

    const includeSubOrganizations = _.get(store,
      ['worldMap', WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, INCLUDE_SUB_ORGS], undefined);

    const filterParams = {
      locationIds,
      dateEnd,
      dateStart,
      polygons: [geoJson],
      personLocationTypes,
      mobileLocationScope,
      organizationIds: personOrgIds,
      includeSubOrganizations,
      ...getPersonAdvanceSearchParams(worldMapState)
    };

    allRequests.push(
      apis.alsat.getAlsatPersonsListConfigPattern({
        params: {
          size: 1,
          locale
        },
        data: filterParams
      })
        .then(response => parsePersonPolygonFeatureResult(response, filterParams))
    );
  }

  if (layers.countryThreatZone || layers.provinceThreatZone || layers.cityThreatZone) {
    const threatZoneOrgId = _.get(store, ['worldMap', 'threatZoneFilter', THREAT_ZONE_ORGS]);

    let locationTiers = [];
    if (layers.countryThreatZone) {
      locationTiers.push('COUNTRY');
    }
    if (layers.provinceThreatZone) {
      locationTiers.push('ADMINISTRATIVE_DIVISION');
    }
    if (layers.cityThreatZone) {
      locationTiers.push('CITY');
    }

    const threatZoneLevels = _.get(store, ['worldMap', 'threatZoneFilter', 'threatZoneLevels']);
    let ratingRange;
    if (threatZoneLevels?.length > 0) {
      ratingRange = {from: threatZoneLevels[0], to: threatZoneLevels[1]};
    }

    allRequests.push(
      apis.geoThreatZones.getThreatZonesWithPolygon(threatZoneOrgId, geoJson, locale, {locationTiers, ratingRange, locationIds})
        .then(response => parseThreatZonePolygonFeatureResult(response, locale))
    );
  }

  if (layers.sites) {
    const commonOrgsSelected = _.get(store, ['worldMap', 'personFilter', 'personOrgs']);
    const siteTypesFilter = _.get(store, ['worldMap', 'siteFilter', SITE_TYPES_FILTER]);
    const locationsFilter = _.get(store, ['worldMap', 'locationsFilter']);
    const data ={
      polygons: [geoJson],
      siteTypeIds: _.map(siteTypesFilter, 'value'),
      organizationIds: commonOrgsSelected?.length > 0 ? commonOrgsSelected : undefined,
      locationIds: locationsFilter?.length > 0 ? locationsFilter : undefined
    };
    allRequests.push(
      apis.sites.getSiteSummaries({data})
        .then(response => parseSitePolygonFeatureResult(response, data))
    );
  }

  return allRequests;
}

/**
 * Returns global state current worldMap.provinceFeature feature if isProvinceFeature else returns
 * worldMap.detailFeature
 * @param getState
 * @param isProvinceFeature
 * @returns {undefined}
 */
export function getStateCurrentFeature(getState, isProvinceFeature) {
  return isProvinceFeature ?
    _.cloneDeep(_.get(getState(), 'worldMap.provinceFeature')) :
    _.cloneDeep(_.get(getState(), 'worldMap.detailFeature'));
}

/**
 * Pulls needed props from previousFeature
 * @param previousFeature
 * @returns {{featureCollection: undefined, countryId: undefined}}
 */
export function getPreviousFeatureProps(previousFeature) {
  const featureCollection = _.get(previousFeature, ['featureCollection']);
  const countryId = _.get(previousFeature, ['properties', 'countryId']);
  return {
    featureCollection,
    countryId
  };
}

/**
 * Base on feature layer ID return dispatch object for ProvinceFeature if province layer
 * else dispatch object for detailFeature
 *
 * @param detailFeature
 * @returns {{payload: {}, type: (string)}}
 */
export function formProvinceOrDetailDispatchObject(detailFeature) {
  const isProvinceFeature = isProvinceRatingFeature(detailFeature);
  const type = isProvinceFeature ? types.SET_MAP_PROVINCE_FEATURE : types.SET_MAP_DETAIL_FEATURE;
  const payload = {[isProvinceFeature ? STATE_PROP.PROVINCE_FEATURE : STATE_PROP.DETAIL_FEATURE]: detailFeature};
  return {type, payload};
}

/**
 * Interface to update local storage for 'world-map-state' key
 *
 * @param path The path (used by Lodash) to the element in the store
 * @param value The value to set that element.
 * @returns undefined
 */
export const updateLocalStorage = (path, value) => {
  setWorldMapLocalStorage(_.set(getWorldMapLocalStorage(), path, value));
};

export function getDefaultStartTime() {
  return moment().utc().startOf('minutes').subtract(1, 'days');
}

export function getDefaultEndTime() {
  return moment().utc().startOf('minutes');
}

export function extendedBoundNeedsUpdating(zoom, bounds, worldMap) {
  const currentZoom = _.get(worldMap, ['zoom']);
  const currentExtendedBounds = _.get(worldMap, ['extendedBounds'],
    turf.envelope(turf.multiPoint([[0, 0], [0, 0]])));
  const boundsEnvelope = turf.envelope(turf.multiPoint(bounds));

  // if current extend bounds contains current bounds, OR zoom(int) has changed
  return !turf.booleanContains(currentExtendedBounds, boundsEnvelope)
    || parseInt(currentZoom) !== parseInt(zoom);
}

export function calculateExtendedBounds(zoom, bounds) {
  if (zoom > MAX_NONEXTENDED_BOUNDS_ZOOM) {
    const lngDistance = bounds[1][0] - bounds[0][0];
    const latDistance = bounds[1][1] - bounds[0][1];

    return turf.envelope(turf.multiPoint([
      [bounds[0][0] - lngDistance, Math.max(bounds[0][1] - latDistance, -90)],
      [bounds[1][0] + lngDistance, Math.min(bounds[1][1] + latDistance, 90)]
    ]));
  }
  //else
  return MAX_EXTENDED_BOUNDS;

}

export function getExtendedBoundsIfNecessary(zoom, bounds, worldMap) {
  if (extendedBoundNeedsUpdating(zoom, bounds, worldMap)) {
    return calculateExtendedBounds(zoom, bounds);
  }
}

export function getDefaultDateRange() {
  const beginDateTime = moment().utc().add(5, 'minutes').startOf('minutes').subtract(1, 'days');
  const endDateTime = moment().utc().add(5, 'minutes').startOf('minutes');
  return {
    beginDateTime, endDateTime
  };
}

export function getPermissionsObjectFromOrgId(orgId, state) {
  const ratingPermissions = state?.session?.organizationsDictionary?.[orgId]?.permissions || {};
  return Object.keys(ratingPermissions).reduce((res, item) => ({...res, [item]: item}), {});
}

// transform all aggregated permission ids array to an object of ids
export function getAggregatedPermissionIdsObject(state) {
  return state?.session?.aggregatedPermissionIds?.reduce((res, item) => ({...res, [item]: item}), {});
}

export function getPermissionsObjectFromOrgIds(orgIds, state) {
  return orgIds.reduce((res, orgId) => {
    const perm = getPermissionsObjectFromOrgId(orgId, state)
    res = {...res, ...perm};
    return res;
  }, {});
}

export * from './alert/utils';
export * from './threat-zone/utils';
export * from './rating/utils';
export * from './person/utils';
