import PropTypes from 'prop-types';
import _ from 'lodash';

import {isEmptyString} from '../../../utils/form-utils';
import Crisis24Logo from './Crisis24Logo';
import ClientOrganizationLogo from './ClientOrganizationLogo';

/**
 * If there is a client configured logo, return it, otherwise return the crisis24 logo
 * */
function SingleLogo(props) {

  const {clientLogoUrl} = props;

  const hasNoClientLogo = _.isNil(clientLogoUrl) || isEmptyString(clientLogoUrl);

  return (
    <>
      {
        hasNoClientLogo
          ? <Crisis24Logo />
          : <ClientOrganizationLogo clientLogoUrl={clientLogoUrl} />
      }
    </>
  )
}

SingleLogo.propTypes = {
  clientLogoUrl: PropTypes.string
}

export default SingleLogo;