import PropTypes from 'prop-types';

import {Divider, Space} from 'antd';

import useAntDesignBreakPoints from '../../../hooks/useAntDesignBreakPoints/useAntDesignBreakPoints';
import SingleLogo from './SingleLogo';
import Crisis24Logo from './Crisis24Logo';
import ClientOrganizationLogo from './ClientOrganizationLogo';

import './HeaderLogo.css';

function HeaderLogo(props) {

  const {clientLogoUrl} = props;

  const {isExtraSmall: isLessThan576px} = useAntDesignBreakPoints();

  return (
    <Space
      size={10}
      split={<Divider className={'logo-divider'} type="vertical" />}
      className="HeaderLogo"
    >
      {
        isLessThan576px ?
          <SingleLogo clientLogoUrl={clientLogoUrl} />
          :
          <>
            <Crisis24Logo />
            {
              clientLogoUrl &&
              <ClientOrganizationLogo clientLogoUrl={clientLogoUrl}/>
            }
          </>
      }
    </Space>
  )
}

HeaderLogo.propTypes = {
  clientLogoUrl: PropTypes.string
}

export default HeaderLogo;