export const MAP_MOBILE_LOCATIONS = 'MAP_MOBILE_LOCATIONS';
export const MAP_TRIPS = 'MAP_TRIPS';
export const ADDRESS = 'ADDRESS';
export const EXPATRIATION = 'EXPATRIATION';
export const PERSON_GROUP = 'personGroup';
export const PERSON_MOBILE_LOCATIONS_RADIO = 'personMobileLocationsRadio';
export const ADDRESSES_LOCATIONS_RADIO = 'personAddressesRadio';
export const PERSON_ORGS = 'personOrgs';
export const INCLUDE_SUB_ORGS = 'includeSubOrganizations';
export const ML_ALL = 'ML_ALL';
export const PRIMARY_ONLY = 'PRIMARY_ONLY';
export const ALL = 'ALL';
export const LAST = 'LAST';
export const ML_LAST_ONLY = 'ML_LAST_ONLY';
export const SEPARATE_MOBILE_LOCATIONS = "separateMobileLocations"
export const AIRLINE_ID = 'airlineId';
export const AIRPORT_ID = 'airportId';
export const HOTEL_NAME = 'hotelNameLike';
export const FLIGHT_NUMBER_LIKE = 'flightNumberLike';
export const RAILWAY = 'railwayNameLike';
export const TRAIN_NUMBER = 'trainNumberLike';
export const SITE = 'SITE';
export const OCCUPANT = 'OCCUPANT';
export const POC = 'POC';
